// auth.js
import Keycloak from 'keycloak-js';

let keycloakInstance = null;

const initKeycloak = () => {
  if (keycloakInstance) {
    return Promise.resolve(keycloakInstance.authenticated);
  }

  keycloakInstance = new Keycloak({
    url: 'https://auth.askgreg.fr',
    realm: 'rtinventory-realm',
    clientId: 'rtinventory-react-dev'
  });

  return keycloakInstance.init({
    onLoad: 'login-required',  // Redirige vers la page de connexion si non authentifié
    checkLoginIframe: true,
    checkLoginIframeInterval: 5
  }).then(authenticated => {
    console.log('Keycloak authenticated:', authenticated);
    return authenticated;
  }).catch(error => {
    console.error('Keycloak init error:', error);
    throw error; // Propagation de l'erreur pour un traitement ultérieur
  });
};

const getToken = () => keycloakInstance ? keycloakInstance.token : null;

const updateToken = (minValidity) => keycloakInstance ? keycloakInstance.updateToken(minValidity) : Promise.reject('Keycloak not initialized');

const logout = () => {
  if (keycloakInstance) {
    const logoutOptions = { redirectUri: window.location.origin };
    return keycloakInstance.logout(logoutOptions);
  }
  return Promise.reject('Keycloak not initialized');
};

export { initKeycloak, getToken, updateToken, logout, keycloakInstance };