import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

function Products() {
  const { user } = useAuth();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [visionModels, setVisionModels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [productModal, setProductModal] = useState({
    open: false,
    isEdit: false,
    id: null,
    name: '',
    description: '',
    product_category_id: '',
    object_classes: '',
    vision_model_id: '',
  });

  const fetchProducts = useCallback(async () => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await axios.get('https://rtinventory.cloud.backend.askgreg.fr/products', {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
      setErrorMessage("Failed to fetch products. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [user.token]);

  const fetchCategories = useCallback(async () => {
    try {
      const response = await axios.get('https://rtinventory.cloud.backend.askgreg.fr/product-categories', {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching product categories:", error);
    }
  }, [user.token]);

  const fetchVisionModels = useCallback(async () => {
    try {
      const response = await axios.get('https://rtinventory.cloud.backend.askgreg.fr/vision-models', {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setVisionModels(response.data);
    } catch (error) {
      console.error("Error fetching vision models:", error);
    }
  }, [user.token]);

  useEffect(() => {
    fetchProducts();
    fetchCategories();
    fetchVisionModels();
  }, [fetchProducts, fetchCategories, fetchVisionModels]);

  const getCategoryName = (categoryId) => {
    const category = categories.find((c) => c.id === categoryId);
    return category ? category.name : "";
  };

  const handleAddProduct = async () => {
    try {
      await axios.post('https://rtinventory.cloud.backend.askgreg.fr/products', productModal, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setProductModal({ ...productModal, open: false });
      fetchProducts();
    } catch (error) {
      console.error("Error adding product:", error);
      setErrorMessage("Failed to add product. Please try again.");
    }
  };

  const handleEditProduct = async () => {
    try {
      await axios.put(`https://rtinventory.cloud.backend.askgreg.fr/products/${productModal.id}`, productModal, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setProductModal({ ...productModal, open: false });
      fetchProducts();
    } catch (error) {
      console.error("Error updating product:", error);
      setErrorMessage("Failed to update product. Please try again.");
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await axios.delete(`https://rtinventory.cloud.backend.askgreg.fr/products/${productId}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      fetchProducts();
    } catch (error) {
      console.error("Error deleting product:", error);
      setErrorMessage("Failed to delete product. Please try again.");
    }
  };

  return (
    <div>
      <h1 className="title">Products</h1>
      <h2 className="subtitle">Manage the products</h2>

      {isLoading && <div>Loading...</div>}
      {errorMessage && <div className="notification is-danger">{errorMessage}</div>}

      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th className="has-text-left is-size-7">Name</th>
            <th className="has-text-left is-size-7">Description</th>
            <th className="has-text-left is-size-7">Category</th>
            <th className="has-text-left is-size-7">Class</th>
            <th className="has-text-left is-size-7">Vision Model</th>
            <th className="has-text-right is-size-7">Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.id}>
              <td className="has-text-left is-size-7">{product.name}</td>
              <td className="has-text-left is-size-7">{product.description}</td>
              <td className="has-text-left is-size-7">{getCategoryName(product.product_category_id)}</td>
              <td className="has-text-left is-size-7">{product.object_classes}</td>
              <td className="has-text-left is-size-7">{product.vision_model ? product.vision_model.name : 'N/A'}</td>
              <td className="has-text-right">
                <button className="button is-primary is-light is-small" onClick={() => setProductModal({ ...product, open: true, isEdit: true })}>
                  <i className="fas fa-edit"></i> Edit
                </button>
                <button className="button is-danger is-light is-small" onClick={() => handleDeleteProduct(product.id)}>
                  <i className="fas fa-trash-alt"></i> Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button className="button is-primary is-light is-small" onClick={() => setProductModal({ open: true, isEdit: false, name: '', description: '', product_category_id: '', object_classes: '', vision_model_id: '' })}>
        <i className="fas fa-plus"></i> Add Product
      </button>

      {/* Modal pour ajouter/éditer un produit */}
      <div className={`modal ${productModal.open ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => setProductModal({ ...productModal, open: false })}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{productModal.isEdit ? 'Edit Product' : 'Add Product'}</p>
            <button className="delete" aria-label="close" onClick={() => setProductModal({ ...productModal, open: false })}></button>
          </header>
          <section className="modal-card-body">
            <div className="field">
              <label className="label">Name</label>
              <div className="control">
                <input className="input" type="text" value={productModal.name} onChange={(e) => setProductModal({ ...productModal, name: e.target.value })} required />
              </div>
            </div>
            <div className="field">
              <label className="label">Description</label>
              <div className="control">
                <textarea className="textarea" value={productModal.description} onChange={(e) => setProductModal({ ...productModal, description: e.target.value })}></textarea>
              </div>
            </div>
            <div className="field">
              <label className="label">Category</label>
              <div className="control">
                <div className="select">
                  <select value={productModal.product_category_id} onChange={(e) => setProductModal({ ...productModal, product_category_id: e.target.value })}>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>{category.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label">Object Classes</label>
              <div className="control">
                <input className="input" type="text" value={productModal.object_classes} onChange={(e) => setProductModal({ ...productModal, object_classes: e.target.value })} />
              </div>
            </div>
            <div className="field">
              <label className="label">Vision Model</label>
              <div className="control">
                <div className="select">
                  <select value={productModal.vision_model_id} onChange={(e) => setProductModal({ ...productModal, vision_model_id: e.target.value })}>
                    <option value="">Select a vision model</option>
                    {visionModels.map((model) => (
                      <option key={model.id} value={model.id}>{model.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-primary" onClick={productModal.isEdit ? handleEditProduct : handleAddProduct}>Submit</button>
            <button className="button" onClick={() => setProductModal({ ...productModal, open: false })}>Cancel</button>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Products;