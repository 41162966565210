// index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome

console.log("Starting React application");

const container = document.getElementById('root');
const root = createRoot(container);

console.log("Rendering root component");

root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

window.addEventListener('error', (event) => {
  console.error('Uncaught error:', event.error);
});