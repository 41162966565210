// src/components/Unauthorized.js
import React from 'react';
import { Link } from 'react-router-dom';

const Unauthorized = () => {
  return (
    <div className="container">
      <div className="notification is-danger">
        <h1 className="title">Accès non autorisé</h1>
        <p>Désolé, vous n'avez pas les autorisations nécessaires pour accéder à cette page.</p>
        <p>Si vous pensez qu'il s'agit d'une erreur, veuillez contacter votre administrateur.</p>
        <Link to="/dashboard" className="button is-primary mt-4">
          Retour au tableau de bord
        </Link>
      </div>
    </div>
  );
};

export default Unauthorized;