// Entities.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

function Entities() {
  const { user } = useAuth();
  const [entities, setEntities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [addEntityModalOpen, setAddEntityModalOpen] = useState(false);
  const [editEntityModalOpen, setEditEntityModalOpen] = useState(false);
  const [currentEntity, setCurrentEntity] = useState({
    name: '',
    address: '',
    phone: '',
    email: '',
  });

  useEffect(() => {
    if (user && user.token) {
      fetchEntities(user.token);
    }
  }, [user]);

  const fetchEntities = async (token) => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await axios.get('https://rtinventory.cloud.backend.askgreg.fr/entities', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEntities(response.data);
    } catch (error) {
      console.error("Error fetching entities:", error);
      setErrorMessage("Une erreur s'est produite lors de la récupération des entités.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddEntity = async () => {
    try {
      await axios.post('https://rtinventory.cloud.backend.askgreg.fr/entities', currentEntity, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      setAddEntityModalOpen(false);
      fetchEntities(user.token);
    } catch (error) {
      console.error("Error adding entity:", error);
      setErrorMessage("Erreur lors de l'ajout de l'entité.");
    }
  };

  const handleEditEntity = async () => {
    try {
      await axios.put(`https://rtinventory.cloud.backend.askgreg.fr/entities/${currentEntity.id}`, currentEntity, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      setEditEntityModalOpen(false);
      fetchEntities(user.token);
    } catch (error) {
      console.error("Error updating entity:", error);
      setErrorMessage("Erreur lors de la mise à jour de l'entité.");
    }
  };

  const handleDeleteEntity = async (entityId) => {
    try {
      await axios.delete(`https://rtinventory.cloud.backend.askgreg.fr/entities/${entityId}`, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      fetchEntities(user.token);
    } catch (error) {
      console.error("Error deleting entity:", error);
      setErrorMessage("Erreur lors de la suppression de l'entité.");
    }
  };

  const openAddEntityModal = () => {
    setCurrentEntity({
      name: '',
      address: '',
      phone: '',
      email: '',
    });
    setAddEntityModalOpen(true);
  };

  const openEditEntityModal = (entity) => {
    setCurrentEntity({ ...entity });
    setEditEntityModalOpen(true);
  };

  return (
    <div>
      <h1 className="title">Entities</h1>
      <h2 className="subtitle">Manage restaurants & stores</h2>

      {isLoading && <div>Loading...</div>}
      {errorMessage && <div className="notification is-danger">{errorMessage}</div>}

      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th className="has-text-left is-size-7">Name</th>
            <th className="has-text-left is-size-7">Address</th>
            <th className="has-text-left is-size-7">Phone</th>
            <th className="has-text-left is-size-7">Email</th>
            <th className="has-text-right is-size-7">Actions</th>
          </tr>
        </thead>
        <tbody>
          {entities.map((entity) => (
            <tr key={entity.id}>
              <td className="has-text-left is-size-7">{entity.name}</td>
              <td className="has-text-left is-size-7">{entity.address}</td>
              <td className="has-text-left is-size-7">{entity.phone}</td>
              <td className="has-text-left is-size-7">{entity.email}</td>
              <td className="has-text-right">
                <button className="button is-primary is-light is-small" onClick={() => openEditEntityModal(entity)}>
                <span className="icon is-small">
                  <i className="fas fa-edit"></i>
                  </span>
                  <span>Edit</span>
                </button>
                <button className="button is-danger is-light is-small" onClick={() => handleDeleteEntity(entity.id)}>
                <span className="icon is-small">
                  <i className="fas fa-trash-alt"></i>
                </span>
                <span>Delete</span>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button className="button is-primary is-light is-small" onClick={openAddEntityModal}>
        <i className="fas fa-plus"></i> Add Entity
      </button>

      {/* Modal pour ajouter une entité */}
      <div className={`modal ${addEntityModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => setAddEntityModalOpen(false)}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Add Entity</p>
            <button className="delete" aria-label="close" onClick={() => setAddEntityModalOpen(false)}></button>
          </header>
          <section className="modal-card-body">
            <div className="field">
              <label className="label">Name</label>
              <div className="control">
                <input 
                  className="input" 
                  type="text" 
                  value={currentEntity.name} 
                  onChange={(e) => setCurrentEntity({ ...currentEntity, name: e.target.value })} 
                  required 
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Address</label>
              <div className="control">
                <input 
                  className="input" 
                  type="text" 
                  value={currentEntity.address} 
                  onChange={(e) => setCurrentEntity({ ...currentEntity, address: e.target.value })} 
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Phone</label>
              <div className="control">
                <input 
                  className="input" 
                  type="tel" 
                  value={currentEntity.phone} 
                  onChange={(e) => setCurrentEntity({ ...currentEntity, phone: e.target.value })} 
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Email</label>
              <div className="control">
                <input 
                  className="input" 
                  type="email" 
                  value={currentEntity.email} 
                  onChange={(e) => setCurrentEntity({ ...currentEntity, email: e.target.value })} 
                />
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-primary is-light is-small" onClick={handleAddEntity}>Submit</button>
            <button className="button is-danger is-light is-small" onClick={() => setAddEntityModalOpen(false)}>Cancel</button>
          </footer>
        </div>
      </div>

      {/* Modal pour éditer une entité */}
      <div className={`modal ${editEntityModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => setEditEntityModalOpen(false)}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Edit Entity</p>
            <button className="delete" aria-label="close" onClick={() => setEditEntityModalOpen(false)}></button>
          </header>
          <section className="modal-card-body">
            <div className="field">
              <label className="label">Name</label>
              <div className="control">
                <input 
                  className="input" 
                  type="text" 
                  value={currentEntity.name} 
                  onChange={(e) => setCurrentEntity({ ...currentEntity, name: e.target.value })} 
                  required 
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Address</label>
              <div className="control">
                <input 
                  className="input" 
                  type="text" 
                  value={currentEntity.address} 
                  onChange={(e) => setCurrentEntity({ ...currentEntity, address: e.target.value })} 
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Phone</label>
              <div className="control">
                <input 
                  className="input" 
                  type="tel" 
                  value={currentEntity.phone} 
                  onChange={(e) => setCurrentEntity({ ...currentEntity, phone: e.target.value })} 
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Email</label>
              <div className="control">
                <input 
                  className="input" 
                  type="email" 
                  value={currentEntity.email} 
                  onChange={(e) => setCurrentEntity({ ...currentEntity, email: e.target.value })} 
                />
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-primary is-light is-small" onClick={handleEditEntity}>Submit</button>
            <button className="button is-danger is-light is-small" onClick={() => setEditEntityModalOpen(false)}>Cancel</button>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Entities;