// Navbar.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import logo from '../assets/logo-blanc.png';

const Navbar = ({ onPeriodChange }) => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('Navbar user:', user);
    if (user !== null) {
      setLoading(false);
    }
  }, [user]);

  const handleLogout = async () => {
    try {
      await logout();
      // The logout function in AuthContext will handle the state update
      // and Keycloak will handle the redirect
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const handlePeriodChange = (event) => {
    const period = event.target.value;
    onPeriodChange(period);
    navigate('/');
  };

  const isAdmin = user?.roles?.includes('admin');

  return (
    <nav className="navbar is-dark is-fixed-top" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
        <a
          role="button"
          className={`navbar-burger ${isNavbarOpen ? 'is-active' : ''}`}
          aria-label="menu"
          aria-expanded="false"
          onClick={toggleNavbar}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div className={`navbar-menu ${isNavbarOpen ? 'is-active' : ''}`}>
        <div className="navbar-start">
          
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">Dashboard</a>
            <div className="navbar-dropdown">
              <a className="navbar-item" onClick={() => handlePeriodChange({ target: { value: 'today' } })}>Today</a>
              <a className="navbar-item" onClick={() => handlePeriodChange({ target: { value: 'yesterday' } })}>Yesterday</a>
              <a className="navbar-item" onClick={() => handlePeriodChange({ target: { value: 'lastweek' } })}>Last Week</a>
              <a className="navbar-item" onClick={() => handlePeriodChange({ target: { value: 'lastmonth' } })}>Last Month</a>
            </div>
          </div>
          {isAdmin && (
            <>
              <Link to="/users" className="navbar-item" onClick={() => setIsNavbarOpen(false)}>Users</Link>
              <Link to="/entities" className="navbar-item" onClick={() => setIsNavbarOpen(false)}>Entities</Link>
            </>
          )}
          
          <Link to="/storageareas" className="navbar-item" onClick={() => setIsNavbarOpen(false)}>Storage</Link>
          <Link to="/racks" className="navbar-item" onClick={() => setIsNavbarOpen(false)}>Racks</Link>
          <Link to="/products" className="navbar-item" onClick={() => setIsNavbarOpen(false)}>Products</Link>
          <Link to="/categories" className="navbar-item" onClick={() => setIsNavbarOpen(false)}>Categories</Link>
          <Link to="/stock" className="navbar-item" onClick={() => setIsNavbarOpen(false)}>Stock</Link>
          <Link to="/sessions" className="navbar-item" onClick={() => setIsNavbarOpen(false)}>Sessions</Link>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            {loading ? 'Loading...' : `Hello, ${user?.name || 'Guest'}`}
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link is-arrowless">
              <i className="fa-solid fa-user user-icon"></i>
            </a>
            <div className="navbar-dropdown is-right">
              <a className="navbar-item" onClick={handleLogout}>Log out</a>
              <hr className="navbar-divider" />
              <div className="navbar-item">Version 1.0.0</div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;